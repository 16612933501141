<!--
 * @Author: lbh
 * @Date: 2021-09-24 10:09:06
 * @LastEditors: lbh
 * @LastEditTime: 2022-07-22 09:07:51
 * @Description:  聯絡我們 - gingersoft
-->

<template>
  <div
    class="contact t-body contact-box"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="contact"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <!--  大圖 -->
      <div
        class="header"
        :style="`background-image:url(${getImgPath('contact_bg01.png')});`"
      >
        <div class="top-box">
          <div class="icon-group">
            <img
              :src="getImgPath('contact_logo.png')"
              class="logo"
            >
            <div class="title-box">
              <div class="title">餐飲服務系統整合專家</div>
              <el-button
                class="btn"
                type="primary"
                @click="goFreeUse"
              >立即開始免費試用</el-button>
            </div>
            <div class="icons">
              <div
                class="icon"
                v-for="(item,index) in iconData"
                :key="index"
              >
                <div class="b">
                  <img :src="item.icon" />
                  <div v-text="item.name"></div>
                </div>

              </div>
            </div>
          </div>
          <div class="phone-box">
            <img
              class="phone"
              :src="getImgPath('contact_phone.png')"
            />
            <div class="phone-content">
              <div
                v-if="isSubmit"
                class="isSubmit"
              >
                <img
                  class="img"
                  src="../../assets/default/isSubmit.png"
                />
                <div class="message">
                  <div class="title">已發送</div>
                  <div class="desc">已經收到您嘅資料，好快會有專人同您聯繫！</div>
                </div>
              </div>
              <div class="bar">
                聯絡我們
              </div>
              <div class="body">
                <div
                  class="input-box"
                  :class="rules.name ? `error` : ``"
                >
                  <div class="label">
                    <span>*</span>姓名
                  </div>
                  <el-input
                    v-model="form.name"
                    @focus="rules.name = false"
                    placeholder="請填寫你的姓名"
                  ></el-input>
                </div>
                <div
                  class="input-box"
                  :class="rules.phone ? `error` : ``"
                >
                  <div class="label">
                    <span>*</span>聯絡方式(電話)
                  </div>
                  <el-input
                    v-model="form.phone"
                    @focus="rules.phone = false"
                    maxlength="8"
                    placeholder="請填寫你的聯絡方式"
                    type="tel"
                  ></el-input>
                  <div
                    class="error"
                    v-if="error.phone"
                    v-text="error.phone"
                  ></div>
                </div>
                <div
                  class="input-box"
                  :class="rules.restaurantName ? `error` : ``"
                >
                  <div class="label">
                    <span>*</span>餐廳名稱
                  </div>
                  <el-input
                    v-model="form.restaurantName"
                    @focus="rules.restaurantName = false"
                    placeholder="請填寫你的餐廳名稱"
                  ></el-input>
                </div>
                <div class="input-box">
                  <div class="label">
                    備註
                  </div>
                  <el-input
                    v-model="form.description"
                    type="textarea"
                    :rows="3"
                    placeholder="請填寫你的備註"
                  ></el-input>
                </div>

                <el-button
                  class="btn"
                  type="primary"
                  @click.stop="addHomeUserFun"
                  v-loading="btnLoading"
                >完成</el-button>
              </div>

            </div>
          </div>

        </div>
        <div class="foot">
          <div class="title-box">
            <div class="title1">
              幫你從經營到營銷到營利
            </div>
            <div class="title2">
              你嘅一體化智能餐飲服務專家
            </div>
          </div>
          <div class="btn">
            <div>從小店到大店</div>
            <div>成為連鎖嘅關鍵</div>
          </div>
        </div>
      </div>
      <!-- 三個卡片 -->
      <div class="content-box">
        <div
          class="item"
          v-for="(item,index) in cardData"
          :key="index"
        >
          <img
            class="img"
            :src="item.img"
          />
          <div
            class="title"
            v-text="item.title"
          ></div>
          <div
            class="content"
            v-text="item.content"
          ></div>
        </div>
      </div>

    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="left">
        <img
          class="logo"
          :src="getImgPath('contact_logo.png')"
        />
        <div class="name">飯糰有限公司 Ricepon Limited</div>
      </div>
      <div class="right">
        <div class="phone">
          <div class="title">聯絡我們:</div>
          <div class="con">8200 2022</div>
        </div>
        <div class="facebook">
          FaceBook:
          <a href="https://facebook.com/gspos">facebook.com/gspos</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import util from "@/js/utils/util";
import { addHomeUser } from "@/js/api/api";
export default {
  components: {
    selfHeaderN,
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
      },
      iconData: [],
      cardData: [],
      form: {
        name: '',
        phone: '',
        emailAddress: ' ',
        restaurantName: '',
        restaurantType: '1',
        description: '',
        source: 1
      },
      btnLoading: false,
      rules: {
        name: false,
        phone: false,
        restaurantName: false
      },
      error: {
        phone: ""
      },
      isSubmit: false
    }
  },
  mounted () {
    util.setTitle('聯絡我們 – 飯糰有限公司 Ricepon');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    let iconData = [];
    iconData.push({ icon: this.getImgPath('contact_icon_01.png'), name: 'POS' });
    iconData.push({ icon: this.getImgPath('contact_icon_02.png'), name: 'Mobile POS' });
    iconData.push({ icon: this.getImgPath('contact_icon_03.png'), name: '採購系統' });
    iconData.push({ icon: this.getImgPath('contact_icon_04.png'), name: '電子支付' });
    iconData.push({ icon: this.getImgPath('contact_icon_05.png'), name: '優惠券' });
    iconData.push({ icon: this.getImgPath('contact_icon_06.png'), name: '會員系統' });
    iconData.push({ icon: this.getImgPath('contact_icon_07.png'), name: '預約服務' });
    iconData.push({ icon: this.getImgPath('contact_icon_08.png'), name: '外賣自取' });
    iconData.push({ icon: this.getImgPath('contact_icon_09.png'), name: '外賣外送' });
    iconData.push({ icon: this.getImgPath('contact_icon_10.png'), name: 'QR Code 點餐' });
    iconData.push({ icon: this.getImgPath('contact_icon_11.png'), name: '排隊服務' });
    this.iconData = iconData;

    let cardData = [];
    cardData.push({ img: this.getImgPath('contact_mark_01.png'), title: '個性化定制', content: '打造屬於你餐廳嘅服務方案，無論係日式定西式，係放題或fine dining，想QR Code點餐又得，想做埋外賣送餐又得。' });
    cardData.push({ img: this.getImgPath('contact_mark_02.png'), title: '技術服務任你揀', content: '可以選擇POS系統，又可以有最新雲端餐飲服務，而且可以一次過做埋採購、打印、結算及支付連結，省時又省力。' });
    cardData.push({ img: this.getImgPath('contact_mark_03.png'), title: '幫你搵埋生意', content: '提供營銷矩陣方案，打造熱點，為新店增加曝光率及帶來流量，唔洗擔心新鋪無人知。有埋會員系統同優惠券促銷活動，帶新客之餘，舊客都一直跟實你！' });
    this.cardData = cardData;
  },
  created () { },
  methods: {
    // 初始化
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      if (w < 700) {
        this.window.type = '600';
      } else if (w < 1000) {
        this.window.type = '900';
      } else {
        this.window.type = '1000';
      }
    },
    // 圖片初始化
    getImgPath (t) {
      return util.getImgPath(t);
    },
    // 提交信息
    addHomeUserFun () {
      let form = this.form;
      if (!form.name) {
        this.rules.name = true;
        return;
      } else {
        this.rules.name = false;
      }
      if (!form.phone) {
        this.rules.phone = true;
        return;
      } else {
        if (form.phone.length < 8) {
          this.error.phone = '請輸入8位數電話號碼';
          this.rules.phone = true;
          return;
        } else if (!util.regexTest.hk(form.phone)) {
          this.error.phone = '請輸入正確的電話號碼';
          this.rules.phone = true;
          return;
        }
        this.error.phone = '';
        this.rules.phone = false;
      }
      if (!form.restaurantName) {
        this.rules.restaurantName = true;
        return;
      } else {
        this.rules.restaurantName = false;
      }
      this.btnLoading = true;
      addHomeUser(this.form).then(res => {
        this.btnLoading = false;
        if (res.success) {
          this.isSubmit = true;
          this.$message({
            message: '謝謝,已發送您的資訊',
            type: 'success'
          });
        } else {
          this.$message.error('發生錯誤,請檢查您的資料信息');
        }
      }).catch(err => { this.btnLoading = false; })
    },
    goFreeUse () {
      window.location.href = 'https://forms.gle/AnGsCK6Y34bsXJrW6';
    }
  }
}
</script>
<style lang="less" scoped>
.contact-box {
  .s-body {
    .header {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .top-box {
        padding: 20px 100px 0;
        display: flex;
        align-items: center;
        position: relative;
        .icon-group {
          flex-grow: 1;
          margin-right: 30px;
          .logo {
            vertical-align: middle;
            margin-bottom: 20px;
          }
          .title-box {
            display: flex;
            align-items: center;
            .title {
              font-size: 60px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #f7f6fa;
              text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
            }
            .btn {
              margin-left: 20px;
              padding-top: 20px;
              padding-bottom: 20px;
              background-color: rgba(190, 28, 66, 1);
              border-color: rgba(190, 28, 66, 1);
              &:hover {
                color: #fff;
              }
            }
          }
          .icons {
            display: flex;
            flex-wrap: wrap;
            .icon {
              display: flex;
              flex-direction: column;
              align-items: center;
              .b {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  width: 82px;
                  height: 82px;
                }
                div {
                  font-size: 19px;
                  font-family: PingFangSC-Medium;
                  color: #ffffff;
                  margin-top: 10px;
                }
              }
            }
          }
        }
        .phone-box {
          position: relative;
          &::after {
            position: absolute;
            width: 96px;
            content: "";
            bottom: 24px;
            left: calc(50% - 48px);
            height: 4px;
            background-color: rgb(10, 10, 10);
            border-radius: 4px;
          }
          .phone {
            background-color: rgb(240, 236, 234);
            border-radius: 50px;
          }
          .phone-content {
            position: absolute;
            width: calc(100% - 44px);
            height: calc(100% - 60px);
            left: 22px;
            top: 40px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .isSubmit {
              position: absolute;
              background: #f0ecea;
              z-index: 10;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              border-radius: 0 0 26px 26px;
              align-items: center;
              .img {
                width: calc(100% - 40px);
                margin-top: 80px;
              }
              .message {
                margin-top: 30px;
                .title {
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: 800;
                  color: #3c3c3c;
                  text-align: center;
                }
                .desc {
                  font-size: 14px;
                  margin-top: 10px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #3c3c3c;
                  text-align: center;
                }
              }
            }
            .bar {
              height: 40px;
              box-shadow: 0px 12px 12px -8px #ccc;
              font-size: 14px;
              font-family: PingFangSC-Regular;
              text-align: center;
              line-height: 40px;
              color: #000000;
            }
            .body {
              flex-grow: 1;
              background-color: rgb(245, 245, 245);
              border-radius: 0 0 26px 26px;
              display: flex;
              flex-direction: column;
              .input-box {
                padding: 10px;
                .label {
                  font-size: 14px;
                  font-family: PingFangSC-Regular;
                  color: #1e1e1e;
                  span {
                    color: #be1c42;
                  }
                }
                .error {
                  color: #be1c42;
                  font-size: 14px;
                }
                & /deep/ .el-input {
                  margin-top: 10px;
                  input {
                    border-radius: 8px;
                    // &:focus {
                    //   border-color: #be1c42;
                    // }
                  }
                }
                & /deep/ .el-textarea {
                  margin-top: 10px;
                  textarea {
                    max-height: 100px;
                  }
                }
              }
              .input-box.error {
                & /deep/ .el-input {
                  margin-top: 10px;
                  input {
                    border-color: #be1c42;
                  }
                }
              }
              .btn {
                background-color: #1196db;
                border-color: #1196db;
                width: calc(100% - 20px);
                margin: auto 10px 30px;
              }
            }
          }
        }
      }
      .foot {
        background-color: rgba(187, 28, 66, 0.7);
        min-height: 60px;
        padding: 0 100px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        .title-box {
          display: flex;
          align-items: center;
          .title1 {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #f7f6fa;
          }
          .title2 {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #f7f6fa;
            margin-left: 20px;
          }
        }

        .btn {
          margin-left: auto;
          width: 310px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #ffffff;
          border-radius: 50px;
          div {
            &:nth-of-type(1) {
              font-size: 20px;
              font-family: FZLTZCHK--GBK1-0;
              color: #be1c42;
              font-weight: 700;
            }
            &:nth-of-type(2) {
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #be1c42;
            }
          }
        }
      }
    }
    .content-box {
      display: flex;
      // padding: 40px 100px;
      justify-content: space-between;
      width: 1170px;
      margin: 0 auto;
      padding: 40px 0;
      .item {
        width: calc(33.33% - 30px);
        max-width: 360px;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        &:nth-of-type(1) {
          margin-left: 0;
        }
        .img {
          width: 100%;
          border-radius: 10px 10px 0 0;
        }
        .title {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #be1c42;
          padding: 10px 15px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 120px;
            height: 6px;
            background: rgba(190, 28, 66, 0.26);
            border-radius: 4px;
            bottom: -7px;
            left: 15px;
          }
        }
        .content {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #919191;
          padding: 15px;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    background-color: #be1c42;
    min-height: 100px;
    color: #fff;
    .left {
      display: flex;
      align-items: flex-end;
      width: 50%;
      justify-content: flex-end;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 38px;
        top: calc(50% - 19px);
        background-color: #fff;
        right: 0;
      }
      .logo {
        height: 26px;
      }
      .name {
        font-size: 14px;
        font-family: PingFangSC-Bold;
        margin-left: 20px;
        margin-right: 56px;
      }
    }
    .right {
      display: flex;
      width: 50%;
      align-items: flex-end;
      .phone {
        margin-left: 56px;
        display: flex;
        align-items: flex-end;
        .title {
          font-size: 14px;
          font-family: PingFangSC-Bold;
        }
        .con {
          font-size: 24px;
          font-family: PingFangSC-Heavy;
          color: #ffffff;
          line-height: 22px;
          margin-left: 5px;
        }
      }
      .facebook {
        font-size: 14px;
        font-family: PingFangSC-Bold;
        margin-left: 20px;
        a {
          color: #fff;
        }
      }
    }
  }
}

// 媒體
@media only screen and (max-width: 600px) {
  .contact-box {
    .s-body {
      .header {
        .top-box {
          flex-direction: column !important;
          padding: 20px;
          .icon-group {
            .title-box {
              flex-direction: column;
              .title {
                font-size: 30px;
                text-align: center;
              }
              .btn {
                margin: 10px 0 0 !important;
              }
            }

            margin-right: 0;
            .icons {
              padding: 0 !important;
              margin-bottom: 20px;
              .icon {
                width: 33.33% !important;
                align-items: center;
                margin-right: 0 !important;
                div {
                  font-size: 16px !important;
                }
              }
            }
          }
        }
      }
      .content-box {
        width: calc(100% - 40px);
        max-width: 1170px;
        flex-wrap: wrap;
        .item {
          width: 100% !important;
          max-width: 100%;
          margin-bottom: 30px;
          &:nth-of-type(3) {
            margin-top: 0px !important;
          }
        }
      }
    }
    .footer {
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      padding: 20px 10%;
      .left {
        margin-bottom: 10px !important;
        width: auto;
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-start;
        &::after {
          width: 0;
        }
        .name {
          margin: 0;
          margin-top: 10px;
        }
      }
      .right {
        flex-direction: column;
        align-items: baseline;
        width: auto;
        .phone {
          margin: 0;
        }
        .facebook {
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .contact-box {
    .s-body {
      .header {
        .foot {
          padding: 20px 10%;
          flex-wrap: wrap;
          .title-box {
            flex-wrap: wrap;
            .title2 {
              margin-top: 10px;
              margin-left: 0;
            }
          }
          .btn {
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .contact-box {
    .s-body {
      .header {
        .top-box {
          .icon-group {
            .icons {
              padding: 0 10px;
              .icon {
                width: 116px;
                margin-top: 30px;
                margin-right: 20px;
                .b {
                  img {
                    // align-self: baseline;
                  }
                }
              }
            }
          }
        }
      }
      .content-box {
        width: 80%;
        max-width: 1170px;
        flex-wrap: wrap;
        .item {
          width: 45%;
          min-width: 45%;
          &:nth-of-type(3) {
            margin-top: 30px;
          }
        }
      }
    }
    .footer {
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      padding-left: 10%;
      .left {
        margin-bottom: 20px;
        width: auto;
        justify-content: flex-start;
        &::after {
          width: 0;
        }
      }
      .right {
        width: auto;
        .phone {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .contact-box {
    .s-body {
      .header {
        .top-box {
          .icon-group {
            .icons {
              max-width: 816px;
              .icon {
                width: 116px;
                margin-top: 30px;
                margin-right: 20px;
              }
            }
          }
        }
      }
      .content-box {
        width: 80%;
        max-width: 1170px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .contact-box {
    .s-body {
      .header {
        .foot {
          .title-box {
            flex-wrap: wrap;
            .title2 {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
